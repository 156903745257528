import React, { useState ,useEffect,useRef, useMemo } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AppProvider, Navigation, NavigationItem, Router as ToolpadRouter } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { PageContainer } from '@toolpad/core/PageContainer';
import { useNavigate, BrowserRouter, Routes as _Routes, Route } from 'react-router-dom';
import { Box, Typography, Grid, TablePagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,TextField } from '@mui/material';
import { saveInstruments, getInstruments ,getAccessTokenn,saveAccessToken} from './indexDB';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import ModalPopup from './Spread/ModalPopup';
import { Dropdown_Header } from './Spread/dropdown';
import { Input, Label } from 'semantic-ui-react';
import LoginIcon from '@mui/icons-material/Login';
import CalculateIcon from '@mui/icons-material/Calculate';
import FunctionsIcon from '@mui/icons-material/Functions';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Login from './Login/login';
import MarginCalculator from './Pages/MarginCalculator';



const api_key  = '7f2igorpvba5qtfh';
const kiteUserId = 'NNG999';
const kitePassword = 'Trade@008';
const kiteTotpKey = 'JK645NATFPEITETDY5PNQCJLRGJJY2BO';
const apiSecret = 'wtmiexb4718yoem0byhl15na7631raxw';
const access_token = localStorage.getItem("accessToken");
const ws_url = 'wss://ws.kite.trade';


interface DataRow {
  [key: string]: any;
}
interface NiftyData {
  key: number;
  value: number;
  name: string;
  percentage: number;
  diff?: number;
  isloss?: boolean;
}
interface WebSocketData {
  ticks: Array<{
    instrument_token: number;
    last_price: number;
    ohlc: {
      open: number;
    };
  }>;
}
interface MarketData {
  Name: string;
  [key: string]: any;
}

const NAVIGATION: NavigationItem[] = [
  {
    kind: 'header',
    title: '',
  },
  {
    segment: 'dashboard',
    title: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    segment: 'spread',
    title: 'Spread View',
    icon: <ListAltIcon />,
  },
  {
    segment: 'trade_view',
    title: 'Trade View',
    icon: <FunctionsIcon />,
  },
  {
    segment: 'margin_calculator',
    title: 'Margin Calculator',
    icon: <CalculateIcon />,
  },
  {
    segment: 'login',
    title: 'Login',
    icon: <LoginIcon />,
  },
];

interface LegData {
  RowID: number;
  RowName: string;
  Exchange: string;
  MktSegment: string;
  ContractType: string;
  TokenNumber: string;
  Code: string;
  Symbol: string;
  Description: string;
  BuySellOrder: string;
  Lots: string;
  OptionType: string;
  StrikePrice: string;
}
interface DashboardProps {
  filteredData : DataRow[] ,
}
interface Column {
  id:  'Name' | 'Day_High' | 'Day_Low' | 'High_5' | 'Low_5' | 'High_10' | 'Low_10' | 'High_15' | 'Low_15' | 'High_30' | 'Low_30' | 'High_60' | 'Low_60' | 'Spread' | 'VWAP' | 'Ema20' | 'Ema50' | 'Ema100';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
 
  { id: 'Name', label: 'Name', minWidth: 100 },
  { id: 'Day_Low', label: 'Low', minWidth: 100 },
  { id: 'Day_High', label: 'High',minWidth: 100},
  { id: 'Low_60', label: 'Low', minWidth: 100 },
  { id: 'High_60', label: 'High',minWidth: 100},
  { id: 'Low_30', label: 'Low', minWidth: 100 },
  { id: 'High_30', label: 'High',minWidth: 100},
  { id: 'Low_15', label: 'Low', minWidth: 100 },
  { id: 'High_15', label: 'High',minWidth: 100},
  { id: 'Low_10', label: 'Low', minWidth: 100 },
  { id: 'High_10', label: 'High',minWidth: 100},
  { id: 'Low_5', label: 'Low', minWidth: 100 },
  { id: 'High_5', label: 'High',minWidth: 100},
  { id: 'Spread', label: 'Spread', minWidth: 100 },
  { id: 'VWAP', label: 'VWAP',minWidth: 100},
  { id: 'Ema20', label: '20 EMA', minWidth: 100 },
  { id: 'Ema50', label: '50 EMA',minWidth: 100},
  { id: 'Ema100', label: '100 EMA', minWidth: 100 }
];


const Dashboard: React.FC<DashboardProps> = ({filteredData}) => (
  <div>
   {/* <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: 700 ,minHeight:'700px' ,border: '1px solid black'}}>
        <Table stickyHeader aria-label="sticky table" sx={{ maxHeight: 700   }}>
          <TableHead>
            <TableRow>
            <TableCell  align="center"></TableCell>
            <TableCell  align="center"></TableCell>
              <TableCell sx={{ borderRight: '1px solid black' }} align="center" colSpan={2}>Day</TableCell>
              <TableCell sx={{ borderRight: '1px solid black' }} align="center" colSpan={2}>60 Min</TableCell>
              <TableCell sx={{ borderRight: '1px solid black' }} align="center" colSpan={2}>30 Min</TableCell>
              <TableCell sx={{ borderRight: '1px solid black' }} align="center" colSpan={2}>15 Min</TableCell>
              <TableCell sx={{ borderRight: '1px solid black' }} align="center" colSpan={2}>10 Min</TableCell>
              <TableCell sx={{ borderRight: '1px solid black' }} align="center" colSpan={2}>5 Min</TableCell>
            </TableRow>
            <TableRow>
              {columns.map((column) => (
                column.label == 'Name'? 
                <>
                  <TableCell></TableCell>
                  <TableCell sx={{ borderRight: column.id.includes('high') || column.id == 'Name' ? '1px solid black' : '' }}
                    key={column.id}
                    align={column.align}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                </>
                : <TableCell sx={{ borderRight: column.id.includes('high') || column.id == 'Name' ? '1px solid black' : '' }}
                key={column.id}
                align={column.align}
                style={{ top: 57, minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .map((row:any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.Name}>
                    <TableCell sx={{ borderRight:  '1px solid black' }}>
                        <CloseIcon/>
                    </TableCell>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell  key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper> */}
  </div>
);

// Main application component
const App: React.FC = () => {
  const navigate = useNavigate();
  const [columns, setColumns] = useState<string[]>([]); // Assuming columns is an array of strings
  const [data, setData] = useState<any[]>([]); // Replace 'any' with the specific type if you know the structure of your data
  const [filters, setFilters] = useState<{ [key: string]: any }>({}); // Replace 'any' with a specific type for filters if possible
  const hasFetchedRef = useRef(false); // Initialize a ref
  const [NIFTY, setNIFTY] = useState<NiftyData[]>([{key:0,value:5555,name:"NIFTY 50",percentage:5,diff:0 , isloss : false},{key:0,value:0,name:"BANK NIFTY",percentage:0,diff:0, isloss : false},
    {key:0,value:0,name:"FINNIFTY",percentage:0,diff:0, isloss : false},{key:0,value:0,name:"MID CP NIFTY",percentage:0,diff:0, isloss : false},
    {key:0,value:0,name:"NIFTY NXT50",percentage:0,diff:0, isloss : false}]
  );
  const [Instruments, setInstruments] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [MarketData,setMarketData] = useState<MarketData[]>([]);
  const [open, setOpen] = React.useState(false);
  const [mainText, setMainText] = useState<string>("");


  const handleNavigationClick = (title: string) => {
    if (title === 'Dashboard') {
      navigate('/dashboard');
    }
  };

const navigationWithHandlers = NAVIGATION.map((item: any) => ({
    ...item,
    onClick: item.segment ? () => handleNavigationClick(item.segment) : undefined,
}));

const BRANDING = {
    title: 'Algo Traders',
};
const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const fileContent = e.target?.result;
      if (!fileContent) return;

      let workbook: XLSX.WorkBook;

      // Check the file extension
      if (file.name.endsWith('.csv')) {
        // Parse CSV file
        workbook = XLSX.read(fileContent, { type: 'binary' });
      } else {
        // Parse Excel file
        const arrayBuffer = fileContent as ArrayBuffer;
        workbook = XLSX.read(arrayBuffer, { type: 'array' });
      }

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert the sheet to JSON
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as any[][];
      localStorage.setItem("jsonData", JSON.stringify(jsonData));

      if (jsonData.length > 0) {
        // Set the table headers
        setColumns(jsonData[0] as string[]);
        // Set the table rows data
        setData(jsonData.slice(1));

        const listInstrument: any[] = [];
        jsonData.slice(1).forEach(element => {
          listInstrument.push(element[20]);
        });

        try {
          await axios.get('https://api.insperoninvestmentsolutions.com/api/getLiveData', {
            params: {
              listInstrument,
              api_key: 'your_api_key_here',
              access_token: 'your_access_token_here'
            }
          });
          getLiveData();
        } catch (error) {
          console.error("Error fetching live data:", error);
        }
      }
    };

    if (file.name.endsWith('.csv')) {
      // Read as binary string for CSV
      reader.readAsBinaryString(file);
    } else {
      // Read as array buffer for Excel files
      reader.readAsArrayBuffer(file);
    }
};



interface DataRow {
    [key: number]: string | number | undefined;
}

const handleFilterChange = (column: string, value: string) => {
    setFilters({
      ...filters,
      [column]: value,
    });
};

const filteredData: DataRow[] = data.length > 0
    ? data.filter((row: DataRow) =>
      columns.every((column: string, colIndex: number) => {
        const filterValue: string | undefined = filters[column];
        if (!filterValue) return true;
        const cellValue: string = row[colIndex]?.toString().toLowerCase() || '';
        return cellValue.includes(filterValue.toLowerCase());
      })
    )
: [];

interface Tick {
    instrument_token: number;
    last_price: number;
}

const getLiveData = async () => {
    const ws = new WebSocket('ws://localhost:6655');

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
    };

    ws.onmessage = (event: MessageEvent) => {
      const dataWS = JSON.parse(event.data);
      if (dataWS.ticks) {
        console.log('Received ticks:', dataWS.ticks);

        setData((prevTicks: any[]) => {
          const updatedTicks = prevTicks.map((tick) => {
            const matchingTick = dataWS.ticks.find((newTick: Tick) => newTick.instrument_token === tick[20]);

            
            return matchingTick
              ? [
                ...tick.slice(0, 9),               
                matchingTick.last_price,            
                ...tick.slice(10),                  
              ]
              : tick; 
          });

          return updatedTicks; 
        });
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    ws.onerror = (error: Event) => {
      console.log('WebSocket error:', error);
    };
};


useEffect(() => {
  if (!hasFetchedRef.current) {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.insperoninvestmentsolutions.com/api/instruments');
        await saveInstruments(response.data);
        const storedData: string = await getInstruments();
        const lines = storedData.trim().split('\n');
        const headers = lines[0].split(',');
        hasFetchedRef.current = true;
        const parsedData = await Promise.all(
          lines.slice(1).map(async line => {
            const values = line.split(',');
            const instrument: any = {};
            headers.forEach((header, index) => {
              instrument[header] = values[index];
            });
            return instrument; 
          })
        );

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        const selected_symbol = Array.from(new Set(parsedData.filter(x => 
                                x.exchange === "NFO" && 
                                x.instrument_type === "FUT" && 
                                x.name.includes("NIFTY") && 
                                currentMonth === (new Date(x.expiry).getMonth() + 1) && 
                                currentYear === (new Date(x.expiry).getFullYear())
                              ).map(x => ({
                                key: x.instrument_token,
                                value: x.last_price,
                                name: x.name.trim().replace(/"/g, ''),
                                percentage: 0,
                                diff: 0, // Initialize if needed
                                isloss: false // Initialize if needed
                              }))));

        const instrument_tokens = selected_symbol.map(item => item.key);
        setNIFTY(selected_symbol);
        setInstruments(parsedData);
        await axios.get('https://api.insperoninvestmentsolutions.com/api/getLiveDataIndexs', { params: { instrument_tokens, api_key, access_token } });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };
    fetchData();
    // Cleanup function
    return () => {
      console.log('Performing cleanup operations...');
    };
  }
}, []);

useEffect(() => {
  let ws: WebSocket | null = null;

  const fetchIndexData = async () => {
    try {
      ws = new WebSocket('ws://localhost:6656');

      ws.onopen = () => {
        console.log('Connected to WebSocket server');
      };

      ws.onmessage = (event) => {
        const dataWS: WebSocketData = JSON.parse(event.data);
        if (dataWS && dataWS.ticks && dataWS.ticks.length > 0) {
          console.log('Received ticks index:', dataWS.ticks);

          setNIFTY((prevTicks:any) => {
            
            const updatedTicks = prevTicks.map((tick:any) => {
             
              const matchingTick = dataWS.ticks.find(
                (newTick) => newTick.instrument_token === +tick.key
              );
              const open = matchingTick ? matchingTick.ohlc.open : 0;
              const priceDiff = matchingTick
                ? (open - matchingTick.last_price).toFixed(2)
                : '0';

             
              return matchingTick
                ? {
                    ...tick,
                    value: matchingTick.last_price,
                    diff: priceDiff,
                    isloss: +priceDiff < 0,
                  } 
                : tick; 
            });
            return updatedTicks;
          });
        }
      };

      ws.onclose = () => {
        console.log('Disconnected from WebSocket server');
      };

      ws.onerror = (error) => {
        console.log('WebSocket error:', error);
      };
    } catch (error) {
      console.log(error);
    }
  };

  fetchIndexData();

  return () => {
    if (ws) {
      ws.close();
      console.log('WebSocket connection closed');
    }
  };
}, []); 

const handleButtonClick = () => {
  fileInputRef.current?.click(); // Simulate click on hidden file input
};
const onLoginClick = async () =>{
  try {
    const response = await axios.post('https://api.insperoninvestmentsolutions.com/api/getAccessToken');
    console.log(response.data.accessToken)
    localStorage.setItem("accessToken",response.data.accessToken);
    await saveAccessToken(response.data.accessToken);
    getLiveData();
    // Retrieve data from IndexedDB
  } catch (error) {
    console.log(error);
  }
}

useEffect(() => {
  if (!hasFetchedRef.current) {
    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.insperoninvestmentsolutions.com/pnf_analysis', {
          cancelToken: source.token,
        });
        console.log("Database data",response.data);
        setColumns(Object.keys(response.data[0]));
        setData(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };
    fetchData();
    return () => {
      source.cancel('Operation canceled by the user.'); // Cancel the request if the component unmounts
      console.log('Performing cleanup operations...');
    };
  }
},[]);

const togglePopup = () => {
  setOpen(true);
};


async function fetchAndMergeData(legData: LegData[], RowName: string) {
  const promises = legData.map((tt: LegData) => fetchHistoricalData(tt, RowName, tt.Symbol, tt.OptionType));
  const results = await Promise.all(promises);

  // Merge all the results (assuming each fetch returns an array of data)
  const mergedData = results.flat(); // Or use other merging logic as needed

  return mergedData;
}

const setFormData = (legData: LegData[], RowName: any) => {
  fetchAndMergeData(legData, RowName).then(mergedData => {
    if (mergedData !== undefined && mergedData[0] !== undefined) {
      console.log(mergedData);
      const buyRecords = mergedData.filter(item => item?.BuySellOrder === 'Buy');
      const sellRecords = mergedData.filter(item => item?.BuySellOrder === 'Sell');

      // Determine which type has more records
      const isBuyDominant = buyRecords.length >= sellRecords.length;

      // Function to sum records
      const sumEntries = (entries: any[]) => {
        if (entries.length === 0) return null;

        return entries.reduce((acc: any, entry: any) => {
          if (!acc) return { ...entry };

          // Sum the values for each key
          ['Day_Low', 'Day_High', 'High_5', 'High_10', 'High_15', 'High_30', 'High_60',
           'Low_5', 'Low_10', 'Low_15', 'Low_30', 'Low_60', 'VWAP', 'Spread', 'Ema20', 'Ema50', 'Ema100'].forEach(key => {
            acc[key] = (acc[key] || 0) + (entry[key] || 0);
          });

          // Keep consistent values for these keys
          ['Name', 'Symbol', 'OptionType'].forEach(key => {
            acc[key] = acc[key] || entry[key];
          });

          return acc;
        }, {});
      };

      // Aggregate records based on dominance
      const aggregatedPrimary = isBuyDominant ? sumEntries(buyRecords) : sumEntries(sellRecords);
      const aggregatedSecondary = isBuyDominant ? sumEntries(sellRecords) : sumEntries(buyRecords);

      // Combine the aggregated results
      const combinedData = [aggregatedPrimary, aggregatedSecondary].filter(Boolean);

      // Update market data with merged data
      setMarketData((prevData: MarketData[]) => {
        const updatedData = combinedData.reduce((acc: any[], row: any) => {
          const existingIndex = acc.findIndex((item: any) => item.Symbol === row.Symbol);

          if (existingIndex !== -1) {
            acc[existingIndex] = { ...acc[existingIndex], ...row };
          } else {
            acc.push(row);
          }

          return acc;
        }, [...prevData]);

        return updatedData; // Return the updated state
      });
    }
  }).catch(error => {
    console.error('Error in setFormData:', error);
  });
};

const fetchHistoricalData = async (token:LegData, mainText:string, symbol: string, optionType: string) => {
      try {
        setOpen(false);
        const startTime = getTodayAtNineAM();
        const accessT = localStorage.getItem("accessToken");
        const tokenNumber = token.TokenNumber;
      
        const response = await axios.get('https://api.insperoninvestmentsolutions.com/api/getHistoricalData', { params: { tokenNumber, accessT , day: startTime } });

        const emptyTab = { Name: "",
           InstrumentToken:"",
           BuySellOrder:"",
           Day_Low: 0,
           Day_High: 0,
           Low_60: 0, 
           High_60: 0, 
           Low_30: 0, 
           High_30: 0, 
           Low_15: 0, 
           High_15: 0, 
           Low_10: 0, 
           High_10: 0, 
           Low_5: 0, 
           High_5: 0, 
           Spread: 0, 
           VWAP: 0, 
           Ema20: 0, 
           Ema50: 0, 
           Ema100: 0, 
           symbol: symbol, 
           optionType: optionType
          };
       
        emptyTab.Name = mainText; 

        emptyTab.InstrumentToken = response.data.day.instrumentToken;
        
        emptyTab.BuySellOrder = token.BuySellOrder;

        emptyTab.Day_High = response.data.day.data.candles.length > 0 ? response.data.day.data.candles[0][2] : 0;
        emptyTab.Day_Low = response.data.day.data.candles.length > 0 ? response.data.day.data.candles[0][3] : 0;
  
        emptyTab.High_60 = response.data['60minute'].data.candles.length > 0 ? response.data['60minute'].data.candles[0][2] : 0;
        emptyTab.Low_60 = response.data['60minute'].data.candles.length > 0 ? response.data['60minute'].data.candles[0][3] : 0;
  
        emptyTab.High_30 = response.data['30minute'].data.candles.length > 0 ? response.data['30minute'].data.candles[0][2] : 0;
        emptyTab.Low_30 = response.data['30minute'].data.candles.length > 0 ? response.data['30minute'].data.candles[0][3] : 0;
  
        emptyTab.High_15 = response.data['15minute'].data.candles.length > 0 ? response.data['15minute'].data.candles[0][2] : 0;
        emptyTab.Low_15 = response.data['15minute'].data.candles.length > 0 ? response.data['15minute'].data.candles[0][3] : 0;
  
        emptyTab.High_10 = response.data['10minute'].data.candles.length > 0 ? response.data['10minute'].data.candles[0][2] : 0;
        emptyTab.Low_10 = response.data['10minute'].data.candles.length > 0 ? response.data['10minute'].data.candles[0][3] : 0;
  
        emptyTab.High_5 = response.data['5minute'].data.candles.length > 0 ? response.data['5minute'].data.candles[0][2] : 0;
        emptyTab.Low_5 = response.data['5minute'].data.candles.length > 0 ? response.data['5minute'].data.candles[0][3] : 0;

        
        return emptyTab;
      } catch (error) {
      }
};
function getTodayAtNineAM() {
  const date = new Date();
  
  date.setUTCHours(9, 0, 0, 0);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}+${hours}:${minutes}:${seconds}`;
}
const handleClose = () => {
  setOpen(false);
};

return (
    <AppProvider navigation={navigationWithHandlers} branding={BRANDING}>

      <DashboardLayout>
      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-last', p: 2 }}>
      <Button variant="contained" color="primary" onClick={()=> onLoginClick()} sx={{textTransform: 'none',fontWeight: 'bold',borderRadius: '8px'}} >Login</Button>&nbsp;
      <Button variant="outlined" onClick={togglePopup}>Add Contract</Button> &nbsp;
      <Button variant="outlined" onClick={handleButtonClick}>Get From File</Button>&nbsp;
      <Button variant="outlined" onClick={exportToCSV}>Export Data</Button>&nbsp;
      <ModalPopup show={open} onClose={handleClose} setFormData={setFormData} />
      </Box> */}
    
        <PageContainer>
          <Grid>
            <Grid>
              <_Routes>
                <Route path="/dashboard" element={<Dashboard   filteredData={filteredData}    />} />
                <Route path="*" element={<Dashboard filteredData={MarketData}  />} />
                <Route path="/login" element={<Login />} />
                <Route path="/margin_calculator" element={<MarginCalculator />} />
                
              </_Routes>
            </Grid>
          </Grid>
        </PageContainer>
      </DashboardLayout>
    </AppProvider>
  );
}



interface DashboardLayoutBasicProps {
  window?: () => Window;
}

const DashboardLayoutBasic: React.FC<DashboardLayoutBasicProps> = (props) => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default DashboardLayoutBasic;
