import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Grid, Dialog, Box, Card,DialogActions,CardContent,CardActions, DialogContent, DialogTitle } from '@mui/material';
import { Link } from 'react-router-dom';

const Login = () => {
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [TOTPKey, setTOTPKey] = useState('');
  const [error, setError] = useState('');
  const [showNewCard, setShowNewCard] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleAddNew = () => {
    // Reset form fields and show the empty card
    setNewUsername('');
    setNewPassword('');
    setApiKey('');
    setSecretKey('');
    setTOTPKey('');
    setError('');
    setShowNewCard(true);  // Show the empty form
  };
  const validateForm = () => {
    // Check if all fields are filled
    setIsFormValid(
      newUsername !== '' &&
      newPassword !== '' &&
      apiKey !== '' &&
      secretKey !== '' &&
      TOTPKey !== ''
    );
  };
  useEffect(() => {
    validateForm();
  }, [newUsername, newPassword, apiKey, secretKey, TOTPKey]);
  // Add type annotation for the event parameter 'e' as React.FormEvent<HTMLFormElement>
  const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/register', { username: newUsername, password: newPassword, apiKey, secretKey, TOTPKey });
      alert(response.data.message);
    } catch (err) {
      setError("Error during registration.");
    }
  };

  return (
    <div>
    {/* Add New Button - Positioned to the right */}
    <CardActions sx={{ flex: 1, justifyContent: 'flex-end', padding: 2 }}>
      <Button
        type="button"
        variant="contained"
        color="primary"
        sx={{ textAlign: 'right' }}
        onClick={handleAddNew}
      >
        Add New
      </Button>
    </CardActions>
  
    {/* Conditional rendering for the form */}
    {showNewCard === false ? (
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={14} sm={12} md={8}>
          <Card sx={{ width: '100%', maxWidth: 1900 }}>
            <CardContent>
              <Typography variant="h5" sx={{ textAlign: 'left', marginBottom: 2 }}>
                {'Login'}
              </Typography>
  
              {/* Display error message if it exists */}
              {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
  
              {/* Login Form */}
              <form onSubmit={handleRegister}>
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
                  {/* Individual form fields */}
                  <Box flex={1} minWidth={250}>
                    <TextField
                      label="Username"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={newUsername}
                      onChange={(e) => setNewUsername(e.target.value)}
                    />
                  </Box>
                  <Box flex={1} minWidth={250}>
                    <TextField
                      label="Password"
                      type="password"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </Box>
                  <Box flex={1} minWidth={250}>
                    <TextField
                      label="API Key"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                    />
                  </Box>
                  <Box flex={1} minWidth={250}>
                    <TextField
                      label="Secret Key"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={secretKey}
                      onChange={(e) => setSecretKey(e.target.value)}
                    />
                  </Box>
                  <Box flex={1} minWidth={250}>
                    <TextField
                      label="TOTP Key"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={TOTPKey}
                      onChange={(e) => setTOTPKey(e.target.value)}
                    />
                  </Box>
  
                  {/* Align the buttons horizontally */}
                  <Box>
                    <CardActions sx={{ flex: 1 }}>
                    <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="success"
                          disabled={!isFormValid}  // Disable if form is not valid
                        >
                          {'Login'}
                        </Button>
                    </CardActions>
                  </Box>
  
                  <Box>
                    <CardActions sx={{ flex: 1 }}>
                      <Button type="button" fullWidth variant="contained" color="secondary">
                        Update
                      </Button>
                    </CardActions>
                  </Box>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={2} justifyContent="center">
  <Grid item xs={14} sm={12} md={8}>
    {/* Login Section */}
    <Card sx={{ width: '100%', maxWidth: 1900 }}>
      <CardContent>
        <Typography variant="h5" sx={{ textAlign: 'left', marginBottom: 2 }}>
          {'Login'}
        </Typography>

        {/* Display error message if it exists */}
        {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}

        {/* Login Form */}
        <form onSubmit={handleRegister}>
          <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
            {/* Individual form fields */}
            <Box flex={1} minWidth={250}>
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
              />
            </Box>
            <Box flex={1} minWidth={250}>
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Box>
            <Box flex={1} minWidth={250}>
              <TextField
                label="API Key"
                variant="outlined"
                fullWidth
                margin="normal"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
              />
            </Box>
            <Box flex={1} minWidth={250}>
              <TextField
                label="Secret Key"
                variant="outlined"
                fullWidth
                margin="normal"
                value={secretKey}
                onChange={(e) => setSecretKey(e.target.value)}
              />
            </Box>
            <Box flex={1} minWidth={250}>
              <TextField
                label="TOTP Key"
                variant="outlined"
                fullWidth
                margin="normal"
                value={TOTPKey}
                onChange={(e) => setTOTPKey(e.target.value)}
              />
            </Box>

            {/* Align the buttons horizontally */}
            <Box>
              <CardActions sx={{ flex: 1 }}>
              <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="success"
                          disabled={!isFormValid}  // Disable if form is not valid
                        >
                          {'Login'}
                        </Button>
              </CardActions>
            </Box>

            <Box>
              <CardActions sx={{ flex: 1 }}>
                <Button type="button" fullWidth variant="contained" color="secondary">
                  Update
                </Button>
              </CardActions>
            </Box>
          </Box>
        </form>
      </CardContent>
    </Card>
  </Grid>

  {/* Create New User Section */}
  <Grid item xs={14} sm={12} md={8}>
    <Card sx={{ width: '100%', maxWidth: 1900 }}>
      <CardContent>
        <Typography variant="h5" sx={{ textAlign: 'left', marginBottom: 2 }}>
          {'Create New User'}
        </Typography>

        {/* Display error message if it exists */}
        {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}

        {/* Create New User Form */}
        <form onSubmit={handleRegister}>
          <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
            {/* Individual form fields */}
            <Box flex={1} minWidth={250}>
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
              />
            </Box>
            <Box flex={1} minWidth={250}>
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Box>
            <Box flex={1} minWidth={250}>
              <TextField
                label="API Key"
                variant="outlined"
                fullWidth
                margin="normal"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
              />
            </Box>
            <Box flex={1} minWidth={250}>
              <TextField
                label="Secret Key"
                variant="outlined"
                fullWidth
                margin="normal"
                value={secretKey}
                onChange={(e) => setSecretKey(e.target.value)}
              />
            </Box>
            <Box flex={1} minWidth={250}>
              <TextField
                label="TOTP Key"
                variant="outlined"
                fullWidth
                margin="normal"
                value={TOTPKey}
                onChange={(e) => setTOTPKey(e.target.value)}
              />
            </Box>

            {/* Align the buttons horizontally */}
            <Box>
              <CardActions sx={{ flex: 1 }}>
              <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="success"
                          disabled={!isFormValid}  // Disable if form is not valid
                        >
                          {'Login'}
                        </Button>
              </CardActions>
            </Box>

            <Box>
              <CardActions sx={{ flex: 1 }}>
                <Button type="button" fullWidth variant="contained" color="secondary">
                  Update
                </Button>
              </CardActions>
            </Box>
          </Box>
        </form>
      </CardContent>
    </Card>
  </Grid>
</Grid>
    )}
  </div>
  )
}
  

export default Login;
