import React from 'react';
import logo from './logo.svg';
import './App.css';
import DashboardLayoutBasic from './DashboardLayout'
function App() {
  return (
    <div className="App">
    <DashboardLayoutBasic/>
    </div>
  );
}

export default App;
